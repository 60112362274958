{
  "name": "next-cloudinary",
  "version": "4.28.0",
  "license": "MIT",
  "main": "./dist/index.js",
  "module": "./dist/index.mjs",
  "types": "./dist/index.d.ts",
  "source": "src/index.ts",
  "scripts": {
    "build": "tsup src/index.ts --dts",
    "dev": "tsup src/index.ts --watch --dts",
    "prepublishOnly": "cp ../README.md . && cp ../LICENSE . && yarn build",
    "postpublish": "rm ./README.md && rm ./LICENSE",
    "test": "jest",
    "test:app": "NEXT_PUBLIC_CLOUDINARY_CLOUD_NAME=\"test\" yarn build && cd tests/nextjs-app && yarn build"
  },
  "dependencies": {
    "@cloudinary-util/url-loader": "^3.11.0",
    "@cloudinary-util/util": "^2.2.1"
  },
  "devDependencies": {
    "@babel/core": "^7.19.6",
    "@babel/preset-env": "^7.19.4",
    "@types/jest": "^29.2.0",
    "@types/react": "^18.0.28",
    "@types/react-dom": "^18.0.11",
    "babel-jest": "^29.2.2",
    "dotenv": "^16.0.3",
    "jest": "^29.2.2",
    "jest-environment-jsdom": "^29.2.2",
    "mkdirp": "^3.0.1",
    "ts-jest": "^29.0.3",
    "tsup": "^7.2.0",
    "typescript": "^5.2.2"
  },
  "peerDependencies": {
    "next": "^12 || ^13",
    "react": "^17 || ^18"
  }
}
